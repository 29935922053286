import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PROFILE_SNS_INFO, QR } from '@src/queryKeys';
import usersApi from '@src/apis/usersApi';

const useGetMyUniqueCode = (): UseQueryResult<string, unknown> =>
  useQuery({
    queryKey: [QR],
    queryFn: async () => {
      const res = await usersApi.getMyUniqueCode();
      return res.data.userUniqueCode;
    },
    ...{ suspense: true },
  });

interface SnsInfo {
  instagram: string | null;
  tiktok: string | null;
}

const useGetSnsInfo = (userId: string): UseQueryResult<SnsInfo, unknown> =>
  useQuery<SnsInfo>(
    [PROFILE_SNS_INFO, userId],
    async () => {
      const { data } = await usersApi.getUserSnsInfo(userId as string);

      const snsObj = { instagram: null, tiktok: null } as SnsInfo;
      data.profileInfos.forEach((profile) => {
        if (profile.profileProvider === 'INSTAGRAM') {
          snsObj.instagram = profile.profileUrl;
        } else {
          snsObj.tiktok = profile.profileUrl;
        }
      });

      return snsObj;
    },
    { enabled: !!userId },
  );

export default {
  useGetMyUniqueCode,
  useGetSnsInfo,
};
